<template>
    <v-app>
        <div class="card ">
            <div class="card-body">
                <div class="row">
                    <div class="col-md-6 col-lg-6 col-sm-12">
                        <h3>
                            Assignment Submission
                        </h3>
                        <router-link :to="{name:'dashboard'}">
                            Dashboard
                        </router-link>
                        \
                        Assignment Submission
                    </div>
                    <div class="col-md-6 col-lg-6 col-sm-12 text-right mb-3">
                        <!--                        <button @click="openDialog()" class="btn btn-primary ml-2">-->

                        <!--                            Add Assignment-->
                        <!--                        </button>-->
                    </div>

                    <div class="row mb-5 bg-filter">
                        <div class="col-md-2">
                            <v-text-field
                                    outlined
                                    v-model="search.name"
                                    dense
                                    label="Student Name">

                            </v-text-field>

                        </div>
                        <div class="col-md-2">
                            <v-select
                                    :items="status"
                                    item-value="value"
                                    item-text="title"
                                    v-model="search.submission_status"
                                    outlined
                                    dense
                                    class="form-control"
                                    label="Submission Status">

                            </v-select>

                        </div>

                        <div class="col-md-2 my-auto">
                            <v-btn class="btn btn-primary text-white" depressed :loading="isBusy"
                                   @click="getAssignments">
                                Search
                            </v-btn>
                            <!--                                    <button class="btn btn-secondary ml-2" @click="reset">Reset</button>-->
                        </div>
                    </div>

                    <div class="col-12">
                        <div class="d-flex justify-content-between flex-wrap mt-1">
                            <div class="d-flex mr-3">
                                <a
                                        href="#"
                                        v-if="assignment"
                                        class="text-dark-75 text-hover-primary font-size-h5 font-weight-bold mr-3"
                                >{{assignment.title}}</a
                                >
                                <a href="#"
                                ><i class="flaticon2-correct text-success font-size-h5"></i
                                ></a>
                            </div>
                            <div class="d-flex flex-wrap mb-4 mr-2">
                                <a
                                        href="#"
                                        class="text-dark-50 text-hover-primary font-weight-bold mr-lg-8 mr-5 mb-lg-0 mb-2"
                                ><i class="fa fa-chalkboard-teacher mr-2 font-size-lg"></i> <strong>Due Date
                                    : </strong> {{assignment.formated_due_date}}
                                </a>
                                <a
                                        href="#"
                                        class="text-dark-50 text-hover-primary font-weight-bold"
                                ><i class="fa fa-chalkboard mr-2 font-size-lg"></i
                                >Submission Type : <strong class="text-uppercase">{{formatType(assignment.submission_type)}}</strong></a
                                >
                            </div>
                            <div class="my-lg-0 my-3">
                                <strong v-if="assignment.total_marks">Total Marks : {{assignment.total_marks}}</strong>
                                <strong class="ml-2" v-if="assignment.pass_marks">Pass Marks :
                                    {{assignment.pass_marks}}</strong>
                            </div>


                        </div>
                    </div>


                    <div class="col-12">

                        <v-data-table
                                :loading="isLoading"
                                :headers="headers"
                                :items-per-page="itemPerPage"
                                :items="assignments_submissions"
                        >
                            <template v-slot:item.image="{ item }">
                                <div class="symbol-label">

                                    <img v-if="item.image" :src="item.image" class="cursor-pointer"
                                         alt=""
                                         style="height: 30px;">
                                    <span v-else class="symbol symbol-35 symbol-light-success">
                                                                                              <span
                                                                                                      class="symbol-label font-size-h5 font-weight-bold cursor-pointer"
                                                                                              > {{
                                                                                                  item.full_name.charAt(0)
                                                                                                }} </span>
                                                                                          </span>
                                </div>
                            </template>
                            <template v-slot:item.full_name="{ item }">

                                <router-link v-if="item.submission" :to="{ name:'assignment-submission-detail',params:{
                                                                     assignment_id: item.submission.assignnment.id,
                                                                      student_id:item.student_id
                                                                     }}">
                                    <strong> {{ item.full_name }} </strong>
                                </router-link>
                                <strong v-else> {{ item.full_name }} </strong>

                            </template>
                            <template v-slot:item.submission="{ item }">
                                         <span
                                                 :class="item.submission?'badge badge-success':'badge badge-danger'">{{
                                             (item.submission && item.submission.status) ? item.submission.status : 'Not Submitted'
                                            }}</span>
                            </template>
                            <template v-slot:item.submited_date="{ item }">
                                    <span v-if="item.submission">
                                        {{item.submission.submitted_at}}
                                    </span>
                                <span v-else>
                                            N/A
                                        </span>
                            </template>
                            <template v-slot:item.due_date="{ item }">
                                    <span v-if="assignment">
                                        {{assignment.formated_due_date}}
                                    </span>
                                <span v-else>
                                            N/A
                                        </span>
                            </template>
                            <template v-slot:item.type="{ item }">
                                <strong v-if="item.submission">
                                    <strong class="text-uppercase">{{formatType(item.submission.submission_type)}}</strong>
                                </strong>
                                <span v-else>
                                            N/A
                                        </span>
                            </template>

                            <!--                            <template v-slot:item.action="{ item }">-->
                            <!--                                <div class="kt-widget__toolbar">-->

                            <!--                                    <b-dropdown-->
                            <!--                                            size="sm"-->
                            <!--                                            variant="link"-->
                            <!--                                            toggle-class="custom-v-dropdown"-->
                            <!--                                            no-caret-->
                            <!--                                            right-->
                            <!--                                            no-flip-->
                            <!--                                            text="Actions"-->

                            <!--                                    >-->
                            <!--                                        <template v-slot:button-content>-->
                            <!--                                            <a href="#" class="" data-toggle="dropdown">-->
                            <!--                                                &lt;!&ndash;              <span class="svg-icon svg-icon-success svg-icon-2x">&ndash;&gt;-->
                            <!--                                                &lt;!&ndash;begin::Svg Icon&ndash;&gt;-->
                            <!--                                                <i class="flaticon-more-1"></i>-->
                            <!--                                                &lt;!&ndash;end::Svg Icon&ndash;&gt;-->
                            <!--                                                &lt;!&ndash;              </span>&ndash;&gt;-->
                            <!--                                            </a>-->
                            <!--                                        </template>-->
                            <!--                                        &lt;!&ndash;begin::Navigation&ndash;&gt;-->
                            <!--                                        <div class="navi navi-hover">-->


                            <!--                                            <b-dropdown-text tag="div" class="navi-item">-->

                            <!--                                                <router-link v-if="item.type"-->
                            <!--                                                             :to="{name:'students-summary', params:{id:item.id, type:item.type[0]}}">-->
                            <!--                                                    <a href="#" class="navi-link"-->
                            <!--                                                    >-->
                            <!--                                                              <span class="navi-icon">-->
                            <!--                                                                <i class="flaticon-edit"></i>-->
                            <!--                                                              </span>-->
                            <!--                                                        <span class="navi-text">Edit</span>-->
                            <!--                                                    </a>-->
                            <!--                                                </router-link>-->
                            <!--                                            </b-dropdown-text>-->

                            <!--                                        </div>-->
                            <!--                                        &lt;!&ndash;end::Navigation&ndash;&gt;-->
                            <!--                                    </b-dropdown>-->
                            <!--                                </div>-->
                            <!--                            </template>-->


                        </v-data-table>

                    </div>

                </div>
            </div>
        </div>
    </v-app>
</template>
<script>
    import AssignmentService from "@/core/services/assignement/AssignmentService";
    import {AssignmentMixin} from "@/mixins/AssignmentMixin";
    import AssignmentSubmissionService from "@/core/services/assignement/AssignmentSubmissionService";

    const assignmentService = new AssignmentService();
    const assignmentSubmissionService = new AssignmentSubmissionService();

    export default {
        name: "assignment",
        mixins: [AssignmentMixin],
        components: {},
        computed: {
            assignment_id() {
                return this.$route.params.assignment_id;
            },
        },
        data() {
            return {
                isLoading: false,
                closeOnClick: true,
                assignments_submissions: [],
                itemPerPage: 20,
                search:{
                    name:null,
                    submission_status:null
                },
                headers: [
                    {text: '', value: 'image', filterable: true, sortable: true},
                    {text: 'Full Name', value: 'full_name', filterable: true, sortable: true},
                    {text: 'Submission Status', value: 'submission', filterable: true, sortable: true},
                    {text: 'Submitted Date', value: 'submited_date', filterable: true, sortable: true},
                    {text: 'Due Date', value: 'due_date', filterable: true, sortable: true},
                    {text: 'Type', value: 'type', filterable: true, sortable: true},
                ],
                status:[
                    {
                        title:'Submitted',
                        value:'submitted',
                    },
                    {
                        title:'Not Submitted',
                        value:'not_submitted',
                    },
                ]

            };
        },
        mounted() {
            this.getSingleAssignment(this.assignment_id);
            this.getAssignments();

        },
        methods: {
            getAssignments() {
                this.isBusy = true;
                this.isLoading = true;

                assignmentSubmissionService.paginate(this.assignment_id,this.search, this.page).then(response => {

                    this.assignments_submissions = response.data;
                    this.page = response.data.meta.current_page;
                    this.total = response.data.meta.total;
                    this.perPage = response.data.meta.per_page;
                }).catch((err) => {
                    // console.log(err)
                }).finally(() => {

                    this.isBusy = false;
                    this.isLoading = false;

                });
            },
            openDialog(assignment) {
                this.$refs["assignment"].showModal(assignment);
            },

            deleteAcademicYear(id) {
                this.$confirm({
                    message: `Are you sure you want to delete this item?`,
                    button: {
                        no: "No",
                        yes: "Yes"
                    },
                    callback: confirm => {
                        if (confirm) {
                            assignmentService.delete(id).then(response => {
                                this.$snotify.success("Classroom deleted");
                                this.getAssignments();
                            });
                        }
                    }
                });
            },
        }
    };
</script>
